import React, { useEffect, useState } from 'react';
import { axios } from '../../../axios'
import './index.css'
import M from 'materialize-css'
import JoditEditor from "jodit-react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom'
import seaPorts from 'sea-ports'
import imgDefault from '../img/product_default.png'

const NewProduct = () => {

	const navigate = useNavigate()
	const puertos = Object.values(seaPorts.JSON)
	const paises = []
	puertos.forEach(p=>{
		if(!paises.includes(p.country))paises.push(p.country)
	})

	const [newData, setNewData] = useState({barcode:'', stock:'', industria_id:'', departamento_id:'', categoria_id:'', item_name:'', use:'', materials:'', model:'', keywords:[], colores:[], attribute_type:'', piece_size:' X  X ', piece_weight:'', features:'', technical_data:'', descripcion:'', descripcion_general:'', packaging_type:'', pieces:'', inner:'', length:'', width:'', height:'', cubic_meter:'', gross_weight_kg:'', net_weight_kg:'', delivery_term_price:'', sell_unit:'', sample:'', sample_price:'', payment_options:[], protections:[], customize:[], logo:[], month_capacity:'', collection_info_origin:'', collection_info_near_port:'', collection_info_hs_code:'', atributos:[]})
	const [categorias, setCategorias] = useState([])
	const [departamentos, setDepartamentos] = useState([])
	const [loader, setLoader] = useState(false)
	const [optionsModel, setOptionsModel] = useState([{value:null, label:'Not Model',}])
	const [optionsKeyword, setOptionsKeyword] = useState([])
	const [industrias, setIndustrias] = useState([])
	const [moqs, setMoqs] = useState([{rango:'-', leadTimeDays:'-', price:''}, {rango:'-', leadTimeDays:'-', price:''},{rango:'-', leadTimeDays:'-', price:''},{rango:'-', leadTimeDays:'-', price:''}])

	useEffect(() => {


		/**
		  *
		  * verifica si ya completo sus datos
		  */
		axios.get('/api/user')
		.then((response)=>{
			if(!response.data.proveedor_completed){
				navigate('/mis-datos')
				M.toast({html:'Please complete your details before!'})
			}
		})


		/*
		   *
		   * get industrias
		   */
		axios.get('/api/seller/get_industry')
		.then((r) => {
			setIndustrias(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))
		/*
		   *
		   * get industrias
		   */
		axios.get('/api/seller/get_my_models_by_departament')
		.then((r) => {
			setOptionsModel([{value:null, label:'Not Model'}, ...r.data])
		})
		.catch(r=>alert(r))
	}, [])

	/*
	   *
	   * get departamentos by industry
	   */
	useEffect(() => {
		if(newData.industria_id)
		axios.get(`/api/seller/get_departament_by_industry/${newData.industria_id}`)
		.then((r) => {
			setDepartamentos(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))

	}, [newData.industria_id])

	/*
	   *
	   * get departamentos by industry
	   */
	useEffect(() => {
		M.FormSelect.init(document.querySelectorAll('select'), {});

	}, [newData.atributos, newData.collection_info_origin])
	
	/*
	   *
	   * get categories by departament
	   */
	useEffect(() => {
		if(newData.departamento_id)
		axios.get(`/api/seller/get_categories_by_departament/${newData.departamento_id}`)
		.then((r) => {
			setCategorias(r.data)
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))

	}, [newData.departamento_id])

	const _dosDecimales = (n) => {
		let t=n.toString();
		let regex=/(\d*.\d{0,2})/;
		if(t.length===0) return 0;
		return t.match(regex)[0];
	}


	const promiseOptionsModel = (search) => {

		return new Promise((resolve, reject) => {

			setTimeout(() => {
				if(search)
				resolve(optionsModel.filter(c=>c.label.includes(search.toUpperCase())));
			}, 300);
		})

	}

	let timeout;
	const promiseOptionsKeyword = (search) => {

		return new Promise((resolve, reject) => {

			clearTimeout(timeout);
			timeout = setTimeout(() => {
			if(search!=='')
				axios.post('/api/seller/post_keyword', {search})
				.then(r=>{
				//sintaxis de options
				//[{"label":"ps5","value":"ps5","__isNew__":true}, ...]
				//ya la retorna el backend
				setOptionsKeyword(r.data)
				resolve(r.data)
			})
			.catch(r=>alert(r))
			}, 500);  // Espera 300 ms antes de enviar la solicitud


		});

	}

	const promiseOptionsPayments = (search) => {
		const payments = [
			{value:'Asiamart', label:'Asiamart'},
			{value:'Credit Card', label:'Credit Card'},
			{value:'D/P D/A', label:'D/P D/A'},
			{value:'Escrow', label:'Escrow'},
			{value:'L/C', label:'L/C'},
			{value:'Money Gram', label:'Money Gram'},
			{value:'PayPal', label:'PayPal'},
			{value:'T/T', label:'T/T'},
		]

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(payments.filter(c=>c.label.includes(search)));
			}, 300);
		})

	}

	const promiseOptionsProtections = (search) => {
		const protections = [
			{value:'Safe trade', label:'Safe trade'},
			{value:'Guarantee', label:'Guarantee'},
			{value:'Refound', label:'Refound'},
			{value:'Policy', label:'Policy'},
		]

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(protections.filter(c=>c.label.includes(search)));
			}, 300);
		})

	}

	const promiseOptionsCustomize = (search) => {
		const protections = [
			{value:'All', label:'All'},
			{value:'Logo', label:'Logo'},
			{value:'Packaging', label:'Packaging'},
		]

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(protections.filter(c=>c.label.includes(search)));
			}, 300);
		})

	}

	const promiseOptionsLogo = (search) => {
		const protections = [
			{value:'Printing', label:'Printing'},
			{value:'Label', label:'Label'},
			{value:'Metal Plate', label:'Metal Plate'},
		]

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(protections.filter(c=>c.label.includes(search)));
			}, 300);
		})

	}

	const promiseOptionsAttributes = (search) => {
		const protections = []

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(protections.filter(c=>c.label.includes(search)));
			}, 300);
		})

	}

	const _pushNewData = (e) => {

		e.preventDefault()

		if(newData.use.length===''){ 
			alert('The "use" field must be required')
			return 0
		}
		if(newData.keywords.length<3){
			alert('The "keywords" field must be longer than 3 options')
			return 0
		}

		const dataF = new FormData()
		dataF.append('data', JSON.stringify({...newData, moqs}))
		console.log(({...newData, moqs}))

		/*
		   * get images
		   */
		dataF.append('packaging_photo', document.getElementById('packaging_photo')?.files[0])
		dataF.append('img_product1', document.getElementById('img_product1')?.files[0])
		dataF.append('img_product2', document.getElementById('img_product2')?.files[0])
		dataF.append('img_product3', document.getElementById('img_product3')?.files[0])
		dataF.append('img_product4', document.getElementById('img_product4')?.files[0])
		dataF.append('img_product5', document.getElementById('img_product5')?.files[0])


		for (var i=0; i<newData.atributos.length; i++){
			let img = document.getElementById(`atributo_imagen_${i}`)?.files[0];
			dataF.append(`atributo_imagen_${i}`, img)
		}

		//logs
		console.log('Contenido del objeto FormData:');
		for (var valor of dataF.entries()) {
			console.log(valor[0] + ':', valor[1]);
		}

		setLoader(true)

		axios.post('/api/seller/catalogo',dataF)
		.then((r) => {
			setLoader(false)
			M.toast({html: 'Agregado al catálogo!'})
			M.FormSelect.init(document.querySelectorAll('select'), {});
			setNewData({industria_id:'', departamento_id:'', categoria_id:'', item_name:'', use:'', materials:'', model:'', keywords:[], color:'', attribute_type:'', piece_size:' X  X ', piece_weight:'', features:'', technical_data:'', descripcion:'', descripcion_general:'', packaging_type:'', pieces:'', inner:'', length:'', width:'', height:'', cubic_meter:'', gross_weight_kg:'', net_weight_kg:'', delivery_term_price:'', sell_unit:'', sample:'', sample_price:'', payment_options:[], protections:[], customize:[], logo:[], month_capacity:'', collection_info_origin:'', collection_info_near_port:'', collection_info_hs_code:'', atributos:[]})
			setMoqs([{rango:' - ', leadTimeDays:' - ', price:''}, {rango:' - ', leadTimeDays:' - ', price:''},{rango:' - ', leadTimeDays:' - ', price:''},{rango:' - ', leadTimeDays:' - ', price:''}])
		})
		.catch(r=>{
			if(r.response.status===400)
			alert(r.response.data.message)
			else alert(r)
			setLoader(false)
		})

	}

	const imgProductSelected = (img, img_targetid) => {
		const reader = new FileReader();
		reader.addEventListener("load", function() {
			document.getElementById(img_targetid).src = reader.result;
		});
		reader.readAsDataURL(img)
	}

	const imgProductSelectedClear = (img_product, producto_img_target) => {
		const input = document.getElementById(img_product);
		input.value = "";
		document.getElementById(producto_img_target).src = imgDefault
	}

	const customLabelColors = ({ data, innerProps }) => (
		<div {...innerProps}  style={{ display: 'flex', alignItems: 'center', cursor:'pointer' }}>
			<div style={{ width: 25, height: 25, backgroundColor: data.value, marginRight: 5 }} />
			{data.label}
		</div>
	);

	const promiseOptionsColors = (search) => {
		const colors = [
			{ value: 'red', label: 'Red' },
			{ value: 'orange', label: 'Orange' },
			{ value: 'yellow', label: 'Yellow' },
			{ value: 'green', label: 'Green' },
			{ value: 'blue', label: 'Blue' },
			{ value: 'purple', label: 'Purple' },
			{ value: 'pink', label: 'Pink' },
			{ value: 'brown', label: 'Brown' },
			{ value: 'gray', label: 'Gray' },
			{ value: 'black', label: 'Black' },
			{ value: 'white', label: 'White' },
		];


		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(colors.filter(c=>c.value.includes(search.toLowerCase())));
			}, 300);
		})
	}

	if(loader)
	return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)

	return(
		<div className='card-panel' style={{height:5500}}>
			<a href='#!' onClick={()=>navigate(-1)}><i className="material-icons">arrow_back</i>BACK</a>
			<h4>New product</h4>
			<form onSubmit={_pushNewData} onKeyDown={(e)=>{if(e.key === 'Enter'){e.preventDefault();}}}>
				<div className='card-panel'>
					<div className='row'>
						<div className="input-field col s12">
							<select value={newData.industria_id} onChange={(e)=>setNewData({...newData, industria_id: e.target.value, departamento_id:'', categoria_id:'', technical_data:industrias.find(i=>i.id===parseInt(e.target.value)).technical_template})} required>
								<option value="" disabled>Choose a industry</option>
								{industrias.map((industria)=>
								<option key={industria.id} value={industria.id}>{industria.industria}</option>
								)}
							</select>
							<label>Industry</label>
						</div>
						<div className="input-field col s12">
							<select value={newData.departamento_id} onChange={(e)=>setNewData({...newData, departamento_id: e.target.value, categoria_id:''})} required>
								<option value="" disabled>Choose a departament</option>
								{departamentos.map((depto)=>
								<option key={depto.id} value={depto.id}>{depto.departamento}</option>
								)}
							</select>
							<label>Departament</label>
						</div>
						<div className="input-field col s12">
							<select value={newData.categoria_id} onChange={(e)=>setNewData({...newData, categoria_id:e.target.value})} >
								<option value="" disabled>Choose a category</option>
								{categorias.map((c)=>
								<option key={c.id} value={c.id}>{c.categoria}</option>
								)}
							</select>
							<label>Category</label>
						</div>
						<div className="input-field col s12">
							<input value={newData.item_name} onChange={(e)=>setNewData({...newData, item_name:e.target.value.slice(0,128)})} id="item_name" type="text" className="validate" />
							<label htmlFor="item_name">Item Name</label>
							<div style={{color: newData.item_name.length<129?'green':'red' }}>{newData.item_name.length}/128</div>
						</div>
						<div className='input-field col m12'>
							Short Description <br />
							<textarea name='descripcion' value={newData.descripcion} onChange={(e) => setNewData({...newData, descripcion:e.target.value.slice(0,120)})} className='materialize-textarea' style={{minHeight:100, border:'solid 1px grey'}} />
							<label style={{color:newData.descripcion.length>120?'red':'green', position:'relative'}}>{newData.descripcion.length}/120 max</label>
						</div>
						<div className='col m12'>
							<div className='input-field col s12'>
								Use <br />
								<textarea name='use' value={newData.use} onChange={(e)=>setNewData({...newData, use:e.target.value.slice(0,300)})} className='materialize-textarea' style={{minHeight:100, border:'solid 1px grey'}} />{/*required*/}
								<label style={{color:newData.use.length>300?'red':'green', position:'relative'}}>{newData.use.length}/300 max</label>
							</div>
						</div>
						<div className="input-field col s12">
							<input value={newData.materials} onChange={(e)=>setNewData({...newData, materials:e.target.value})} id="materials" type="text" className="validate" />
							<label htmlFor="materials">Materials</label>
						</div>
						<div className='col s12'>
							<label>Model Group</label>
							<AsyncSelect value={newData.model} onChange={(e)=>setNewData({...newData, model:{value:e.value?e.value.toUpperCase():null, label:e.label.toUpperCase()}})} defaultOptions={optionsModel} loadOptions={promiseOptionsModel} loadingMessage={()=>'loading...'} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
							<label style={{color:'red'}}>*models are generated by the system</label>
						</div>
						<div className='col s12' style={{marginBottom:10}}>
							<label>Keyword (min 3)</label>
							<AsyncCreatableSelect isMulti formatCreateLabel={(e)=>'New key: '+e.toUpperCase()} value={newData.keywords} onChange={(e)=>setNewData({...newData, keywords:e})} defaultOptions loadOptions={promiseOptionsKeyword} loadingMessage={()=>'loading...'} placeholder="Select your keywords" onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
								<label style={{color:newData.keywords.length<3?'red':'green', position:'relative'}}>{newData.keywords.length}/3 min</label>
						</div>
						<div className="col s12">
							<div className='row'>
								<div>Attributes</div>
								<div className="input-field col s12">
									<select value={newData.attribute_type} onChange={(e)=>setNewData({...newData, attribute_type:e.target.value})}>
										<option value="" disabled>Select</option>
										<option value="No Attribute">No Attribute</option>
										<option value="Size">Size</option>
										<option value="Width">Width</option>
										<option value="Capacity">Capacity</option>
									</select>
									<label>Type</label>
								</div>
								{/*
								  * Codigo que se ejecuta cuando se esta configurando un color(subatributo)
								  *
								  **/}
								{(newData.attribute_type==='No Attribute'||newData.attribute_type==='')&&
									<>
									<div className="col s12" style={{marginTop:20, marginBottom:20}}>
										<div>Color:</div>
										<AsyncSelect isMulti value={newData.colores||''} onChange={(e)=>setNewData({...newData, colores:e})} defaultOptions loadOptions={promiseOptionsColors} loadingMessage={()=>'loading...'} components={{Option: customLabelColors}} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
									</div>
									{newData.colores?.map((atributoColor, indexAtributoColor)=>
										<div className='col s12 m-p-0' key={indexAtributoColor} style={{background:atributoColor.value}}>
											<div style={{background:'rgba(255,255,255,0.95)', padding:10, border: `1px solid ${atributoColor.value}`}}>
												Sub-attributes for <span style={{color:atributoColor.value}}>{atributoColor.label}</span>
												<div className='input-field'>
													<input value={newData.colores[indexAtributoColor].stock||''} onChange={(e)=>setNewData({...newData, colores:newData.colores?.map((color, i)=>indexAtributoColor===i?{...color, stock: e.target.value}:color)})} id="stock" type="number" className="validate m-p-0" onWheel={(e)=>e.target.blur()}/>
													<label htmlFor="stock">Stock</label>
												</div>
												<div className='input-field'>
													<input value={newData.colores[indexAtributoColor].barcode||''} onChange={(e)=>setNewData({...newData, colores:newData.colores?.map((color, i)=>indexAtributoColor===i?{...color, barcode: e.target.value}:color)})} id="barcode-1" type="text" className="validate m-p-0" />
													<label htmlFor="barcode-1">Bar-code</label>
												</div>
											</div>
										</div>
									)}
									</>
								}
								{/*
								  * Codigo que se ejecuta cuando se esta configurando un atributo
								  *
								  **/}
								{(newData.attribute_type!=='No Attribute'&&newData.attribute_type!=='')&&
								<>
								<div className="col s12">
									<div>Register your {newData.attribute_type}'s </div>
									<AsyncCreatableSelect isMulti value={newData.atributos} onChange={(e)=>setNewData({...newData, atributos: e.map(atributo=>({...newData.atributos.find(a=>a.value===atributo.value.toUpperCase()), value:atributo.value.toUpperCase(), label:atributo.label.toUpperCase()}))})} defaultOptions loadOptions={promiseOptionsAttributes} loadingMessage={()=>'loading...'} noOptionsMessage={() => `Typing for create you ${newData.attribute_type}`} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }} placeholder={`Type and select your ${newData.attribute_type}`}/>
								</div>
								<div className="col s12">
									<div className='row' style={{marginTop:20}}>
										<div>Complete data for you {newData.attribute_type}'s: </div>
										{newData.atributos.map((atributo,i)=>
										<div key={i} className="col s4">
											<div className='card-panel'>
												<div className='bold' style={{padding:5, color:'white', backgroundColor:'grey'}}>
													{newData.item_name} {newData.attribute_type}:{atributo.value}
												</div>
												<div className='row' style={{marginBottom:10, padding:'0px 10px 0px 10px'}}>
													<label>Colors</label>
													<AsyncSelect isMulti value={newData.atributos[i].colores||''} onChange={(e)=>setNewData({...newData, atributos: newData.atributos.map((a, j)=>j!==i?a:{...a, colores:e})})} defaultOptions loadOptions={promiseOptionsColors} loadingMessage={()=>'loading...'} components={{Option: customLabelColors}} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
												</div>
												{(!newData.atributos[i].colores || newData.atributos[i].colores?.length===0)&&
												<div className="input-field">
													<input value={newData.atributos[i].barcode||''} onChange={(e)=>setNewData({...newData, atributos: newData.atributos.map((a, j)=>j!==i?a:{...a, barcode:e.target.value})})} id="barcode-2" type="text" className="validate" />
													<label htmlFor="barcode-2">Bar-code</label>
												</div>
												}
												{newData.atributos[i].colores?.map((atributoColor, indexAtributoColor)=>
													<div key={indexAtributoColor} style={{background:atributoColor.value}}>
														<div style={{background:'rgba(255,255,255,0.95)', padding:10, border: `1px solid ${atributoColor.value}`, marginBottom:10}}>
															Sub-attributes for <span style={{color:atributoColor.value}}>{atributoColor.label}</span>
															<div className='input-field'>
																<input value={newData.atributos[i].colores[indexAtributoColor].stock||''} onChange={(e)=>setNewData({...newData, atributos: newData.atributos.map((a, j)=>j!==i?a:{...a, colores: a.colores?.map((color,i)=>indexAtributoColor===i?{...color, stock:e.target.value}:color)})})} id="stock-1" type="number" className="validate m-p-0" onWheel={(e)=>e.target.blur()}/>
																<label htmlFor="stock-1">Stock</label>
															</div>
															<div className='input-field'>
																<input value={newData.atributos[i].colores[indexAtributoColor].barcode||''} onChange={(e)=>setNewData({...newData, atributos: newData.atributos.map((a, j)=>j!==i?a:{...a, colores: a.colores?.map((color,i)=>indexAtributoColor===i?{...color, barcode:e.target.value}:color)})})} id="code-bar-1" type="text" className="validate m-p-0" />
																<label htmlFor="code-bar-1">Bar-code</label>
															</div>
														</div>
													</div>
												)}
												{(!newData.atributos[i].colores || newData.atributos[i].colores?.length===0)&&
												<div className="input-field">
													<input value={newData.atributos[i].stock||''} onChange={(e)=>setNewData({...newData, atributos: newData.atributos.map((a, j)=>j!==i?a:{...a, stock:e.target.value})})} id="stock-2" type="number" className="validate" onWheel={(e)=>e.target.blur()}/>
													<label htmlFor="stock-2">Stock</label>
												</div>
												}
												<div className="file-field input-field">
													<div className="btn">
														<span>Photo</span>
														<input accept=".jpg, .jpeg, .png" type="file" id={`atributo_imagen_${i}`}/>
													</div>
													<div className="file-path-wrapper">
														<input className="file-path validate" type="text" />
													</div>
												</div>
											</div>
										</div>
										)}
									</div>
								</div>
								</>
								}
							</div>
						</div>
						{/*
						  * Codigo que se ejecuta cuando no se configura ni color ni atributos
						  *
						  */}
						{((newData.attribute_type==='No Attribute'||newData.attribute_type==='')&&newData.colores?.length===0)&&
						<div className="col s12">
							<div className='input-field'>
								<input value={newData.stock||''} onChange={(e)=>setNewData({...newData, stock:e.target.value})} id="stock-3" type="number" className="validate m-p-0" onWheel={(e)=>e.target.blur()}/>
								<label htmlFor="stock-3">Stock</label>
							</div>
							<div className='input-field'>
								<input value={newData.barcode||''} onChange={(e)=>setNewData({...newData, barcode:e.target.value })} id="barcode-3" type="text" className="validate m-p-0" />
								<label htmlFor="barcode-3">Bar-code</label>
							</div>
						</div>
						}
						<div className="input-field col s12">
							<div style={{backgroundColor:'grey', color:'white'}}>Piece Size {newData.piece_size!==' X  X '?newData.piece_size.split('X')[0]+'mm X'+newData.piece_size.split('X')[1]+'mm X'+newData.piece_size.split('X')[2]+'mm':''}</div>
							<div className='row'>
								<div className="input-field col s4">
									<input value={newData.piece_size.split(' X ')[0]||''} onChange={(e)=>setNewData({...newData, piece_size: newData.piece_size.split(' X ').map((s,i)=>i===0?e.target.value:s).join(' X ')})} id="piece_size" type="number" placeholder='Large' onWheel={(e)=>e.target.blur()}/>
									<label htmlFor="piece_size">Piece Size Large(mm)</label>
								</div>
								<div className="input-field col">X</div>
								<div className="input-field col s4">
									<input value={newData.piece_size.split(' X ')[1]||''} onChange={(e)=>setNewData({...newData, piece_size: newData.piece_size.split(' X ').map((s,i)=>i===1?e.target.value:s).join(' X ')})} id="piece_size" type="number" placeholder='Width' onWheel={(e)=>e.target.blur()}/>
									<label htmlFor="piece_size">Piece Size Width(mm)</label>
								</div>
								<div className="input-field col">X</div>
								<div className="input-field col s3">
									<input value={newData.piece_size.split(' X ')[2]||''} onChange={(e)=>setNewData({...newData, piece_size: newData.piece_size.split(' X ').map((s,i)=>i===2?e.target.value:s).join(' X ')})}  id="piece_size" type="text" placeholder='Height'/>
									<label htmlFor="piece_size">Piece Size Height(mm)</label>
								</div>
							</div>
						</div>
						<div className="input-field col s12">
							<input value={newData.piece_weight} onChange={(e)=>setNewData({...newData, piece_weight: _dosDecimales(e.target.value)})} id="piece_weight" type="number" className="validate" placeholder='Kilograms' onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="piece_weight">Piece Weight (kg)</label>
						</div>
						<div className="input-field col s12">
							<input value={newData.features} onChange={(e)=>setNewData({...newData, features:e.target.value})} id="features" type="text" className="validate" placeholder='Marca commercial style'/>
							<label htmlFor="features">Features</label>
						</div>
						<div className='col s12'>
							<div className='input-field col s12'>
								Technical Data: <br></br>
								<JoditEditor value={newData.technical_data} tabIndex={1} config={{ readonly: false,askBeforePasteHTML:false}} onBlur={newContent => setNewData({...newData, technical_data:newContent})} />
							</div>
						</div>
						<div className='col s12'>
							<div className='input-field col s12'>
								Custom description: <br></br>
								<JoditEditor value={newData.descripcion_general} tabIndex={1} config={{ readonly: false,askBeforePasteHTML:false}} onBlur={newContent => setNewData({...newData, descripcion_general:newContent})} />
							</div>
						</div>
						<div className='col s12'>
							<div className='bold'>Product Photo</div>
							<p>
								Image file size should by less than 5MB, Supported formats: .jpeg .jpg .png
								Recommended image size is more than 640px*640px. Images should be clear and easy for buyers to view at a galnce
							</p>
							<p>
								Adding a shield to your image can prevent theft
							</p>
							<div className='card-panel'>
								<p>
									<i className="material-icons orange-text">warning</i>
									Main Photo images used in the search, image should be greater than 350 x 350 pixels, The size ratio of the photo should be ranging from 3:4 to 4:3
								</p>
								
							</div>
						</div>

						<div className='col m12'>
							<div className='row'>
								<div className='col'>
									<div style={{display:'flex', flexDirection: "column", alignItems: "center"}}>
										<input accept=".jpg, .jpeg, .png" type="file" id="img_product1" style={{display:'none'}} onChange={(e)=>imgProductSelected(e.target.files[0], 'producto_img_target1')} />
										<label htmlFor="img_product1" style={{cursor: 'pointer'}}>
											<img src={imgDefault} alt="Imagen predeterminada" style={{width: 200, height: 200, objectFit: "contain"}} id='producto_img_target1'/>
										</label>
										<a href='#!' onClick={()=>imgProductSelectedClear('img_product1', 'producto_img_target1')}>delete</a>
									</div>
								</div>
								<div className='col'>
									<div style={{display:'flex', flexDirection: "column", alignItems: "center"}}>
										<input accept=".jpg, .jpeg, .png" type="file" id="img_product2" style={{display:'none'}} onChange={(e)=>imgProductSelected(e.target.files[0], 'producto_img_target2')} />
										<label htmlFor="img_product2" style={{cursor: 'pointer'}}>
											<img src={imgDefault} alt="Imagen predeterminada" style={{width: 200, height: 200, objectFit: "contain"}} id='producto_img_target2'/>
										</label>
										<a href='#!' onClick={()=>imgProductSelectedClear('img_product2', 'producto_img_target2')}>delete</a>
									</div>
								</div>
								<div className='col'>
									<div style={{display:'flex', flexDirection: "column", alignItems: "center"}}>
										<input accept=".jpg, .jpeg, .png" type="file" id="img_product3" style={{display:'none'}} onChange={(e)=>imgProductSelected(e.target.files[0], 'producto_img_target3')} />
										<label htmlFor="img_product3" style={{cursor: 'pointer'}}>
											<img src={imgDefault} alt="Imagen predeterminada" style={{width: 200, height: 200, objectFit: "contain"}} id='producto_img_target3'/>
										</label>
										<a href='#!' onClick={()=>imgProductSelectedClear('img_product3', 'producto_img_target3')}>delete</a>
									</div>
								</div>
								<div className='col'>
									<div style={{display:'flex', flexDirection: "column", alignItems: "center"}}>
										<input accept=".jpg, .jpeg, .png" type="file" id="img_product4" style={{display:'none'}} onChange={(e)=>imgProductSelected(e.target.files[0], 'producto_img_target4')} />
										<label htmlFor="img_product4" style={{cursor: 'pointer'}}>
											<img src={imgDefault} alt="Imagen predeterminada" style={{width: 200, height: 200, objectFit: "contain"}} id='producto_img_target4'/>
										</label>
										<a href='#!' onClick={()=>imgProductSelectedClear('img_product4', 'producto_img_target4')}>delete</a>
									</div>
								</div>
								<div className='col'>
									<div style={{display:'flex', flexDirection: "column", alignItems: "center"}}>
										<input accept=".jpg, .jpeg, .png" type="file" id="img_product5" style={{display:'none'}} onChange={(e)=>imgProductSelected(e.target.files[0], 'producto_img_target5')} />
										<label htmlFor="img_product5" style={{cursor: 'pointer'}}>
											<img src={imgDefault} alt="Imagen predeterminada" style={{width: 200, height: 200, objectFit: "contain"}} id='producto_img_target5'/>
										</label>
										<a href='#!' onClick={()=>imgProductSelectedClear('img_product5', 'producto_img_target5')}>delete</a>
									</div>
								</div>
							</div>
						</div>


						<div className="col m12" style={{background:'grey', color:'white'}}>
							<div className='bold'>PACKING INFORMATION</div>
						</div>
						<div className='input-field col s12'>
							<select value={newData.packaging_type} onChange={(e)=>setNewData({...newData, packaging_type: e.target.value})}>
								<option value="" disabled>Choose a packaging type</option>
								<option value='Cartoon'>Cartoon</option>
								<option value='Bag'>Bag</option>
								<option value='Sack'>Sack</option>
							</select>
							<label>Packaging Type</label>
						</div>
						<div className='col m12'>
							<div className="file-field input-field">
								<div className="btn">
									<span>Packaging Photo</span>
									<input accept=".jpg, .jpeg, .png" type="file" id="packaging_photo"/>
								</div>
								<div className="file-path-wrapper">
									<input className="file-path validate" type="text" />
								</div>
							</div>
						</div>
						<div className='input-field col s12'>
							<input value={newData.pieces}  onChange={(e)=>setNewData({...newData, pieces:e.target.value})} id="pieces" type="number" onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="pieces">Pieces per packaging(number integer)</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.inner}  onChange={(e)=>setNewData({...newData, inner:e.target.value})} id="inner" type="number" onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="inner">Inner(number integer)</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.length}  onChange={(e)=>setNewData({...newData, length:e.target.value})} id="length" type="number" onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="length">Length mm(number decimal example: 1.10mm)</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.width}  onChange={(e)=>setNewData({...newData, width:e.target.value})} id="width" type="number" onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="width">Width mm(number integer example: 1mm)</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.height}  onChange={(e)=>setNewData({...newData, height:e.target.value})} id="height" type="number" onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="height">Height mm(number integer example: 1mm)</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.cubic_meter}  onChange={(e)=>setNewData({...newData, cubic_meter:e.target.value})} id="cubic_meter" type="number" onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="cubic_meter">CBM(number decimal example: 1.10)</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.gross_weight_kg}  onChange={(e)=>setNewData({...newData, gross_weight_kg: _dosDecimales(e.target.value)})} id="gross_weight_kg" type="number" onWheel={(e)=>e.target.blur()} required/>
							<label htmlFor="gross_weight_kg">Gross Weight KG(number decimal example: 1.10)</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.net_weight_kg}  onChange={(e)=>setNewData({...newData, net_weight_kg: _dosDecimales(e.target.value)})} id="net_weight_kg" type="text" required/>
							<label htmlFor="net_weight_kg">Net Weight KG(number decimal example: 1.10)</label>
						</div>
						<div className="col m12" style={{background:'grey', color:'white'}}>
							<div className='bold'>PURCHASE INFORMATION</div>
						</div>
						<div className="input-field col s12">
							<select value={newData.delivery_term_price} onChange={(e)=>setNewData({...newData, delivery_term_price:e.target.value})}>
								<option value="" disabled>Choose a incoterm</option>
								<option value="EXW">EXW</option>
								<option value="FCA">FCA</option>
								<option value="FAS">FAS</option>
								<option value="FOB">FOB</option>
								<option value="CFR">CFR</option>
								<option value="CIF">CIF</option>
								<option value="CPT">CPT</option>
								<option value="CIP">CIP</option>
								<option value="DAP">DAP</option>
								<option value="DAT">DAT</option>
								<option value="DDP">DDP</option>
							</select>
							<label>Delivery Term Price</label>
						</div>
						<div className='input-field col s12'>
							<input value={newData.sell_unit}  onChange={(e)=>setNewData({...newData, sell_unit:e.target.value})} id="sell_unit" type="text" className="validate" placeholder='Cartoon'/>
							<label htmlFor="sell_unit">Sell Unit</label>
						</div>
						<div className='col s12'>
							<div className='row'>
								<div className='bold'>Minimum Order Quantity</div>
								<div className='col m3'>
									<div className='card-panel'>
										moq1
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[0].rango.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===0?{...m, rango: (((e.target.value==='0')?'1':e.target.value)+'-'+m.rango.split('-')[1]) }:m))} id="moq1_rango_min" type="number" placeholder='100 - 200' required />
												<label htmlFor="moq1_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[0].rango.split('-')[1]} onChange={(e)=>setMoqs(moqs.map((m, i)=>i===0?{...m, rango:m.rango.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value)}:m))} id="moq1_rango_max" type="number" placeholder='100 - 200' required/>
												<label htmlFor="moq1_rango_max">Rango max</label>
											</div>
										</div>
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[0].leadTimeDays.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===0?{...m, leadTimeDays: ((e.target.value==='0')?'1':e.target.value)+'-'+m.leadTimeDays.split('-')[1] }:m))} id="moq1_time_min" type="number" placeholder='1 Days' onWheel={(e)=>e.target.blur()} required/>
												<label htmlFor="moq1_time_min">Lead Time Min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[0].leadTimeDays.split('-')[1]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===0?{...m, leadTimeDays: m.leadTimeDays.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value) }:m))} id="moq1_time_max" type="number" placeholder='5 Days' onWheel={(e)=>e.target.blur()} required/>
												<label htmlFor="moq1_time_max">Lead Time Max</label>
											</div>
										</div>
										<div className='input-field'>
											<input value={moqs[0].price}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===0?{...m, price:((e.target.value==='0')?'1':e.target.value)}:m))} id="moq1_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()} required/>
											<label htmlFor="moq1_price">Price/Unit(usd)</label>
										</div>
									</div>
								</div>
								<div className='col m3'>
									<div className='card-panel'>
										moq2
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[1].rango.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===1?{...m, rango: ((e.target.value==='0')?'1':e.target.value)+'-'+m.rango.split('-')[1] }:m))} id="moq2_rango_min" type="number" placeholder='100 - 200'/>
												<label htmlFor="moq2_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[1].rango.split('-')[1]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===1?{...m, rango:m.rango.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value)}:m))} id="moq2_rango_max" type="number" placeholder='100 - 200'/>
												<label htmlFor="moq2_rango_max">Rango max</label>
											</div>
										</div>
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[1].leadTimeDays.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===1?{...m, leadTimeDays: ((e.target.value==='0')?'1':e.target.value)+'-'+m.leadTimeDays.split('-')[1] }:m))} id="moq2_time_min" type="number" onWheel={(e)=>e.target.blur()} placeholder='1 Days'/>
												<label htmlFor="moq2_time_min">Lead Time Min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[1].leadTimeDays.split('-')[1]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===1?{...m, leadTimeDays: m.leadTimeDays.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value) }:m))} id="moq2_time_max" type="number" onWheel={(e)=>e.target.blur()} placeholder='5 Days'/>
												<label htmlFor="moq2_time_max">Lead Time Max</label>
											</div>
										</div>



										<div className='input-field'>
											<input value={moqs[1].price}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===1?{...m, price:((e.target.value==='0')?'1':e.target.value)}:m))} id="moq2_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()}/>
											<label htmlFor="moq2_price">Price/Unit(usd)</label>
										</div>
									</div>
								</div>
								<div className='col m3'>
									<div className='card-panel'>
										moq3
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[2].rango.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===2?{...m, rango: ((e.target.value==='0')?'1':e.target.value)+'-'+m.rango.split('-')[1] }:m))} id="moq3_rango_min" type="number" placeholder='100 - 200'/>
												<label htmlFor="moq3_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[2].rango.split('-')[1]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===2?{...m, rango:m.rango.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value)}:m))} id="moq3_rango_max" type="number" placeholder='100 - 200'/>
												<label htmlFor="moq3_rango_max">Rango max</label>
											</div>
										</div>
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[2].leadTimeDays.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===2?{...m, leadTimeDays: ((e.target.value==='0')?'1':e.target.value)+'-'+m.leadTimeDays.split('-')[1] }:m))} id="moq3_time_min" type="number" placeholder='1 Days' onWheel={(e)=>e.target.blur()}/>
												<label htmlFor="moq3_time_min">Lead Time Min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[2].leadTimeDays.split('-')[1]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===2?{...m, leadTimeDays: m.leadTimeDays.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value) }:m))} id="moq3_time_max" type="number" placeholder='5 Days' onWheel={(e)=>e.target.blur()}/>
												<label htmlFor="moq3_time_max">Lead Time Max</label>
											</div>
										</div>
										<div className='input-field'>
											<input value={moqs[2].price}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===2?{...m, price:((e.target.value==='0')?'1':e.target.value)}:m))} id="moq3_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()}/>
											<label htmlFor="moq3_price">Price/Unit(usd)</label>
										</div>
									</div>
								</div>
								<div className='col m3'>
									<div className='card-panel'>
										moq4
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[3].rango.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===3?{...m, rango: ((e.target.value==='0')?'1':e.target.value)+'-'+m.rango.split('-')[1] }:m))} id="moq4_rango_min" type="number" placeholder='100 - 200'/>
												<label htmlFor="moq4_rango_min">Rango min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[3].rango.split('-')[1]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===3?{...m, rango:m.rango.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value)}:m))} id="moq4_rango_max" type="number" placeholder='100 - 200'/>
												<label htmlFor="moq4_rango_max">Rango max</label>
											</div>
										</div>
										<div className='row'>
											<div className='input-field col s6'>
												<input value={moqs[3].leadTimeDays.split('-')[0]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===3?{...m, leadTimeDays: ((e.target.value==='0')?'1':e.target.value)+'-'+m.leadTimeDays.split('-')[1] }:m))} id="moq4_time_min" type="number" placeholder='1 Days' onWheel={(e)=>e.target.blur()}/>
												<label htmlFor="moq4_time_min">Lead Time Min</label>
											</div>
											<div className='input-field col s6'>
												<input value={moqs[3].leadTimeDays.split('-')[1]}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===3?{...m, leadTimeDays: m.leadTimeDays.split('-')[0]+'-'+((e.target.value==='0')?'1':e.target.value) }:m))} id="moq4_time_max" type="number" placeholder='5 Days' onWheel={(e)=>e.target.blur()}/>
												<label htmlFor="moq4_time_max">Lead Time Max</label>
											</div>
										</div>
										<div className='input-field'>
											<input value={moqs[3].price}  onChange={(e)=>setMoqs(moqs.map((m, i)=>i===3?{...m, price:((e.target.value==='0')?'1':e.target.value)}:m))} id="moq4_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()}/>
											<label htmlFor="moq4_price">Price/Unit(usd)</label>
										</div>
									</div>
								</div>
							</div>

						</div>

						<div className='input-field col m12'>
							<input value={newData.sample} onChange={(e)=>setNewData({...newData, sample:e.target.value})} id="sample" type="text" className="validate" placeholder='1 piece'/>
							<label htmlFor="sample">Sample</label>
						</div>
						<div className='input-field col m12'>
							<input value={newData.sample_price} onChange={(e)=>setNewData({...newData, sample_price:e.target.value})} id="sample_price" type="number" placeholder='$' onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="sample_price">Sample Price(usd)</label>
						</div>

						<div className='col s12'>
							<label>Payment Options</label>
							<AsyncSelect isMulti value={newData.payment_options} onChange={(e)=>{setNewData({...newData, payment_options:e}); console.log(e)}} defaultOptions loadOptions={promiseOptionsPayments} loadingMessage={()=>'loading...'} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
						</div>
						<div className='col s12'>
							<label>Protections</label>
							<AsyncSelect isMulti value={newData.protections} onChange={(e)=>setNewData({...newData, protections:e})} defaultOptions loadOptions={promiseOptionsProtections} loadingMessage={()=>'loading...'} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
						</div>
						<div className='col s12'>
							<label>Customize</label>
							<AsyncSelect isMulti value={newData.customize} onChange={(e)=>setNewData({...newData, customize:e})} defaultOptions loadOptions={promiseOptionsCustomize} loadingMessage={()=>'loading...'} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
						</div>
						<div className='col s12'>
							<label>Logo</label>
							<AsyncSelect isMulti value={newData.logo} onChange={(e)=>setNewData({...newData, logo:e})} defaultOptions loadOptions={promiseOptionsLogo} loadingMessage={()=>'loading...'} onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}/>
						</div>
						<div className='input-field col m12'>
							<input value={newData.month_capacity} onChange={(e)=>setNewData({...newData, month_capacity:e.target.value})} id="month_capacity" type="number" className="validate" onWheel={(e)=>e.target.blur()}/>
							<label htmlFor="month_capacity">Month Capacity(Containers)</label>
						</div>
						<div className="col m12" style={{background:'grey', color:'white'}}>
							<div className='bold'>Collection Information</div>
						</div>
						<div className="input-field col s12">
							<select value={newData.collection_info_origin} onChange={(e)=>setNewData({...newData, collection_info_origin: e.target.value})}>
								<option value="" disabled>Choose a origin</option>
								{paises.map((country, i)=>
								<option key={i} value={country}>{country}</option>
								)}
							</select>
							<label>Origin</label>
						</div>
						<div className="input-field col s12">
							<select value={newData.collection_info_near_port} onChange={(e)=>setNewData({...newData, collection_info_near_port: e.target.value})}>
								<option value="" disabled>Choose a port</option>
								{puertos.filter(p=>p.country===newData.collection_info_origin).map((puerto, i)=>
								<option key={i} value={puerto.name}>{puerto.name}</option>
								)}
							</select>
							<label>Near Port</label>
						</div>
						<div className='input-field col m12'>
							<input value={newData.collection_info_hs_code} onChange={(e)=>setNewData({...newData, collection_info_hs_code:e.target.value})} id="collection_info_hs_code" type="text" className="validate" placeholder='code'/>
							<label htmlFor="collection_info_hs_code">Hs Code</label>
						</div>
					</div>
				</div>
				<button className='btn waves-effect waves-light black'>SAVE PRODUCT</button>
			</form>

	</div>
	)
}

export default NewProduct
